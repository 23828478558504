<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('销售订单.订单编号')" prop="id">
                <a-input
                  v-model="queryParam.id"
                  @pressEnter="handleQuery"
                  :maxLength="18"
                  :placeholder="$t('通用.输入.请输入') + $t('销售订单.订单编号')"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <!--            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('销售订单.三方订单号')" prop="outTradeNo">
                <a-input v-model="queryParam.outTradeNo" @pressEnter="handleQuery" :maxLength="40" :placeholder="$t('通用.输入.请输入')+$t('销售订单.三方订单号')" allow-clear/>
              </a-form-item>
            </a-col>-->
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('销售订单.订单状态')" prop="orderStatusList">
                <a-select
                  mode="multiple"
                  :placeholder="$t('通用.输入.请选择') + $t('销售订单.订单状态')"
                  @change="orderStatusListChange"
                  style="width: 100%"
                  v-model="queryParam.orderStatusList"
                  allowClear
                >
                  <a-select-option
                    v-for="(item, index) in this.customDict.OrderSellStatusEnum"
                    :value="item.type"
                    :key="index"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('商品.商品型号')" prop="remark">
                <a-input
                  v-model="queryParam.remark"
                  @pressEnter="handleQuery"
                  :maxLength="20"
                  :placeholder="$t('通用.输入.请输入') + $t('商品.商品型号')"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('销售订单.退款状态')" prop="orderStatusList">
                  <a-select
                    mode="multiple"
                    :placeholder="$t('通用.输入.请选择') + $t('销售订单.退款状态')"
                    style="width: 100%"
                    v-model="queryParam.refundStatusList"
                    allowClear
                  >
                    <a-select-option
                      v-for="(item, index) in this.customDict.OrderRefundStatusEnum"
                      :value="item.type"
                      :key="index"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('销售订单.买家名称')" prop="buyerName">
                  <a-input
                    v-model="queryParam.buyerName"
                    @pressEnter="handleQuery"
                    :maxLength="20"
                    :placeholder="$t('通用.输入.请输入') + $t('销售订单.买家名称')"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('销售订单.买家信息')" prop="userMobile">
                  <a-input
                    v-model="queryParam.userMobile"
                    @pressEnter="handleQuery"
                    :maxLength="20"
                    :placeholder="$t('通用.输入.请输入') + $t('销售订单.手机号或邮箱')"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('商品.商品分类')" prop="categoryId">
                  <a-tree-select
                    v-model="queryParam.categoryId"
                    style="width: 100%"
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    :tree-data="cateOptions"
                    :placeholder="$t('通用.输入.请选择') + $t('商品.商品分类')"
                    :replaceFields="{ children: 'children', title: 'categoryName', key: 'id', value: 'id' }"
                    tree-default-expand-all
                    allow-clear
                  >
                  </a-tree-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <!--                <a-form-item :label="$t('明星.明星名字')" prop="starId">
                  <a-select
                    allowClear
                    :default-active-first-option="false" v-model="queryParam.starName"
                    :filter-option="false" :show-arrow="false" :not-found-content="null"
                    show-search
                    @search="handleStarSearch"
                    @change="handleStarChange">
                    <a-select-option v-for="(item, index) in starList" :value="item" :key="index">
                      {{ item.starName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>-->
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item prop="goodsName">
                  <span slot="label">
                    <a-tooltip>
                      {{ $t('商品.商品名称') }}
                      <template slot="title"> 请输入商品关键词,多个关键词之间用英文逗号分隔 </template>
                    </a-tooltip>
                  </span>
                  <a-input
                    v-model="queryParam.goodsName"
                    @pressEnter="handleQuery"
                    :maxLength="100"
                    :placeholder="$t('通用.输入.请输入') + $t('商品.商品名称')"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <!--              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('销售订单.下单开始时间')" prop="createBeginTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"
                                 :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                                 valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('销售订单.下单结束时间')" prop="createEndTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"
                                 :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                                 valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>-->
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('销售订单.支付方式')" prop="payTypeList">
                  <a-select
                    mode="multiple"
                    :placeholder="$t('通用.输入.请选择') + $t('销售订单.支付方式')"
                    style="width: 100%"
                    v-model="queryParam.payTypeList"
                    allowClear
                  >
                    <a-select-option
                      v-for="(item, index) in this.customDict.OrderPayTypeEnum"
                      :value="item.type"
                      :key="index"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('销售订单.是否催发货')" prop="isRemind">
                  <a-select
                    :placeholder="$t('通用.输入.请选择') + $t('销售订单.是否催发货')"
                    style="width: 100%"
                    v-model="queryParam.isRemind"
                    allowClear
                  >
                    <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <!--              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('销售订单.地区')" prop="nation">
                  <a-select  :placeholder="$t('通用.输入.请选择')+$t('销售订单.地区')"
                             style="width: 100%" v-model="queryParam.nation" allowClear>
                    <a-select-option v-for="(item, index) in this.customDict.UserNationEnum" :value="item.type"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>-->
              <a-col :md="6" :sm="24"> </a-col>
              <a-col :md="6" :sm="24"> </a-col>
            </template>
            <a-col :md="6" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"
                  ><a-icon type="search" />{{ $t('通用.按钮.查询') }}</a-button
                >
                <a-button style="margin-left: 8px" @click="resetQuery"
                  ><a-icon type="redo" />{{ $t('通用.按钮.重置') }}</a-button
                >
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? $t('通用.按钮.收起') : $t('通用.按钮.展开') }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <a-tabs v-model="activeKey" @tabClick="changeTab">
        <a-tab-pane key="" tab="全部订单" />
        <a-tab-pane v-for="pane in this.customDict.OrderSellStatusEnum" :key="pane.type" :tab="pane.name"> </a-tab-pane>
      </a-tabs>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="handleExport" v-hasPermi="['order:sell:export']">
          <a-icon type="download" />{{ $t('通用.按钮.导出') }}
        </a-button>
        <a-button type="primary" :disabled="multiple" @click="handleSelectExport" v-hasPermi="['order:sell:export']">
          <a-icon type="download" />{{ $t('通用.按钮.选中导出') }}
        </a-button>
        <a-button type="primary" @click="handleDeliveryExport" v-hasPermi="['order:sell:export']">
          <a-icon type="download" />{{ $t('销售订单.导出待发货') }}
        </a-button>
        <a-button type="dashed" @click="$refs.importExcel.importExcelHandleOpen()" v-hasPermi="['order:sell:delivery']">
          <a-icon type="import" />{{ $t('销售订单.导入发货单') }}
        </a-button>
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <detail-form ref="detailForm" @ok="getList" />
      <delivery-form ref="deliveryForm" @ok="getList" />
      <logistic-detail ref="logisticDetail" />
      <import-excel ref="importExcel" @ok="getList" />

      <!-- 数据展示 -->
      <a-table
        :scroll="{ x: '160%' }"
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="payTime" slot-scope="text, record">
          {{ parseTime(record.payTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="orderStatus" slot-scope="text, record">
          <!-- 重写自定义了个字典组件-->
          <custom-dict-tag :options="customDict.OrderSellStatusEnum" :value="record.orderStatus" />
        </span>
        <span slot="isFirstOrder" slot-scope="text, record">
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isFirstOrder" />
        </span>
        <span slot="isRemind" slot-scope="text, record">
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isRemind" />
        </span>
        <span slot="isSend" slot-scope="text, record">
          <span v-if="record.isSend == 0">下单失败</span>
          <span v-else>下单成功</span>
        </span>
        <div slot="goodsTitle" style="width: 400px">
          <a-row>
            {{ $t('销售订单.商品信息') }}
          </a-row>
          <a-row>
            <a-col :span="12">{{ $t('商品.商品名称') }}</a-col>
            <a-col :span="6">{{ $t('商品.商品主图') }}</a-col>
            <a-col :span="6">{{ $t('销售订单.数量') }}</a-col>
          </a-row>
        </div>
        <div slot="orderItemList" slot-scope="text, record">
          <a-table
            :showHeader="false"
            size="small"
            :bordered="false"
            rowKey="skuId"
            :pagination="false"
            :columns="goodsColumns"
            :data-source="record.orderItemList"
          >
            <template slot="picture" slot-scope="text, record">
              <div>
                <img
                  v-if="record.picture"
                  :src="record.picture"
                  style="width: 60px; height: 62px"
                  @click="handleRecordPreview(record.picture)"
                  alt="none"
                />
              </div>
            </template>
          </a-table>
        </div>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" />
          <a @click="$refs.detailForm.handleDetail(record.id)"> <a-icon type="eye" />{{ $t('通用.按钮.详情') }} </a>
          <!--          <a-divider type="vertical"  v-if="record.orderStatus===2015"/>
          <a @click="handleOrders(record.id)" v-hasPermi="['order:sell:orders']"  v-if="record.orderStatus===2015">
            <a-icon type="audit" />{{$t('销售订单.接单')}}
          </a>-->
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              <a-icon type="double-right" />
              {{ $t('通用.按钮.更多') }}
            </a>
            <a-menu slot="overlay">
              <a-menu-item v-hasPermi="['order:sell:cancel']" v-if="record.orderStatus === 2010">
                <a @click="handleCancel(record)" v-hasPermi="['order:sell:cancel']">
                  <a-icon type="edit" />{{ $t('销售订单.取消订单') }}
                </a>
              </a-menu-item>
              <a-menu-item
                v-hasPermi="['order:sell:delivery']"
                v-if="record.orderStatus === 2020 || record.orderStatus === 2025"
              >
                <a @click="$refs.deliveryForm.handleDelivery(record.id)" v-hasPermi="['order:sell:delivery']">
                  <a-icon type="edit" />{{ $t('销售订单.发货') }}
                </a>
              </a-menu-item>
              <a-menu-item v-if="record.isSend == 0 && record.orderStatus === 2020">
                <a @click="retrySendOrder(record)">
                  <a-icon type="edit" />重新下单
                </a>
              </a-menu-item>
              <a-menu-item v-if="record.orderStatus === 2030 || record.orderStatus === 2050">
                <a @click="$refs.logisticDetail.handleDetail(record.id)">
                  <a-icon type="environment" />{{ $t('销售订单.物流详情') }}
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
        <span slot="orderAmount" slot-scope="text, record"> ¥{{ record.orderAmount }} </span>
        <span slot="payAmount" slot-scope="text, record"> ¥{{ record.payAmount }} </span>
        <span slot="shippingPayAmount" slot-scope="text, record"> ¥{{ record.shippingPayAmount }} </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageSell, listSell, ordersSell, cancelSell, retrySendOrder } from '@/api/order/sell'
import { listStar } from '@/api/star/star'

import CustomDictTag from '@/components/DictCustomTag'
import { mapGetters } from 'vuex'
import moment from 'moment'
import LogisticDetail from './modules/LogisticDetail'
import ImportExcel from './modules/ImportExcel'
import detailForm from './modules/detailForm'
import { tableMixin } from '@/store/table-mixin'
import DeliveryForm from '@/views/order/sell/modules/DeliveryForm'
import { listCategory } from '@/api/goods/category'

let starTimeout
let currentStarValue
function getStarList(value, callback) {
  if (starTimeout) {
    clearTimeout(starTimeout)
    starTimeout = null
  }
  currentStarValue = value
  function fake() {
    listStar({ starName: value }).then((response) => {
      if (currentStarValue === value) {
        this.starList = response.data
        callback(response.data)
      }
    })
  }
  starTimeout = setTimeout(fake, 300)
}

export default {
  name: 'Sell',
  components: {
    DeliveryForm,
    CustomDictTag,
    ImportExcel,
    detailForm,
    LogisticDetail,
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      cateOptions: [],
      starList: [],
      activeKey: null,
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo: {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        id: undefined,
        buyerId: undefined,
        nation: undefined,
        starId: undefined,
        categoryId: undefined,
        goodsName: undefined,
        isPay: undefined,
        outTradeNo: undefined,
        buyerName: undefined,
        createBeginTime: undefined,
        createEndTime: undefined,
        payType: undefined,
        payTime: undefined,
        userMobile: undefined,
        remark: undefined,
        orderStatus: undefined,
        orderStatusList: undefined,
        refundStatusList: undefined,
        payTypeList: undefined,
        refundStatus: undefined,
        isFirstOrder: undefined,
        isRemind: undefined,
        pageNum: 1,
        pageSize: 10
      },

      goodsColumns: [
        {
          dataIndex: 'goodsName',
          width: 200,
          align: 'center'
        },
        {
          dataIndex: 'picture',
          width: 100,
          scopedSlots: { customRender: 'picture' },
          align: 'center'
        },
        {
          width: 100,
          dataIndex: 'goodsCount',
          align: 'center'
        }
      ],

      columns: [
        {
          title: this.$t('销售订单.订单编号'),
          dataIndex: 'id',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center',
          width: 160,
          fixed: 'left'
        },
        {
          title: this.$t('销售订单.订单状态'),
          dataIndex: 'orderStatusName',
          width: 100,
          fixed: 'left',
          align: 'center'
        },
        {
          title: this.$t('销售订单.下单时间'),
          dataIndex: 'createTime',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },

        {
          title: this.$t('销售订单.买家名称'),
          dataIndex: 'buyerName',
          align: 'center'
        },
        {
          title: this.$t('销售订单.买家手机号'),
          dataIndex: 'mobile',
          align: 'center'
        },
        {
          title: this.$t('销售订单.商品数量'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'goodsCount',
          align: 'center'
        },
        {
          width: 400,
          dataIndex: 'orderItemList',
          scopedSlots: { customRender: 'orderItemList' },
          slots: { title: 'goodsTitle' },
          align: 'center'
        },
        {
          title: this.$t('销售订单.订单原价'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'orderAmount',
          scopedSlots: { customRender: 'orderAmount' },
          align: 'center'
        },
        {
          title: this.$t('销售订单.实付金额'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'payAmount',
          scopedSlots: { customRender: 'payAmount' },
          align: 'center'
        },
        {
          title: this.$t('销售订单.运费'),
          dataIndex: 'shippingPayAmount',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'shippingPayAmount' },
          align: 'center'
        },
        {
          title: this.$t('销售订单.支付方式'),
          dataIndex: 'payTypeName',
          align: 'center'
        },
        {
          title: this.$t('销售订单.支付时间'),
          dataIndex: 'payTime',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'payTime' },
          align: 'center'
        },
        {
          title: this.$t('销售订单.退款状态'),
          dataIndex: 'refundStatusName',
          align: 'center'
        },
        {
          title: this.$t('销售订单.是否催发货'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'isRemind' },
          dataIndex: 'isRemind',
          align: 'center'
        },
        {
          title: '是否下单成功',
          scopedSlots: { customRender: 'isSend' },
          slots: { title: 'isSend' },
          dataIndex: 'isSend',
          sorter: 1,
          align: 'center'
        },
        {
          title: '失败原因',
          sorter: 1,
          dataIndex: 'reason',
          align: 'center'
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          fixed: 'right',
          width: 150,
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getCategoryList()
    this.getOrderRouteList()
  },
  watch: {
    $route: 'getOrderRouteList'
  },
  mounted() {},
  computed: {
    ...mapGetters(['customDict'])
  },
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共') + total + this.$t('通用.文本.条')
    },
    getOrderRouteList() {
      let params = this.$route.params
      if (params.buyerId) {
        this.queryParam.buyerId = params.buyerId
        this.queryParam.isPay = params.isPay
      }
      this.getList()
    },
    /** 查询销售订单列表 */
    getList() {
      this.loading = true
      pageSell(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    orderStatusListChange() {
      this.queryParam.orderStatus = undefined
    },
    //切换tab
    changeTab(index) {
      if (index == null) {
        index = undefined
      }
      this.queryParam.orderStatus = index
      this.getList()
    },
    //银行选择
    handleStarSearch(value) {
      getStarList(value, (data) => (this.starList = data))
    },
    handleStarChange(value) {
      this.queryParam.starId = value.id
      //getStarList(value, data => (this.starList = data));
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        id: undefined,
        buyerId: undefined,
        nation: undefined,
        starId: undefined,
        categoryId: undefined,
        goodsName: undefined,
        isPay: undefined,
        outTradeNo: undefined,
        buyerName: undefined,
        orderStatusList: undefined,
        refundStatusList: undefined,
        createBeginTime: undefined,
        createEndTime: undefined,
        payType: undefined,
        payTypeList: undefined,
        payTime: undefined,
        userMobile: undefined,
        remark: undefined,
        orderStatus: undefined,
        refundStatus: undefined,
        isFirstOrder: undefined,
        isRemind: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = ''
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /*页面初始化时获取分类类别*/
    getCategoryList() {
      listCategory().then((response) => {
        this.categoryList = response.data
        let children = this.handleTree(response.data, 'id', 'pid')
        console.log('children', children)
        this.cateOptions = children
      })
    },
    retrySendOrder(record) {
      retrySendOrder(record.id).then((response) => {
        if (response.success == true) {
          this.getList()
          this.$message.success(response.message, 3)
        }
      })
    },
    handleOrders(record) {
      ordersSell({ id: record.id }).then(() => {
        this.onSelectChange([], [])
        this.getList()
        const message = this.$t('销售订单.接单成功')
        this.$message.success(message, 3)
      })
    },

    handleCancel(record) {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.确认取消'),
        onOk() {
          return cancelSell({ id: record.id }).then(() => {
            that.onSelectChange([], [])
            that.getList()
            const message = that.$t('通用.文本.取消成功')
            that.$message.success(message, 3)
          })
        },
        onCancel() {}
      })
      this.getList()
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk() {
          that.download('order/sell/export', that.queryParam, `销售订单_${new Date().getTime()}.xlsx`)
        },
        onCancel() {},
      })
    },

    handleSelectExport() {
      var that = this
      let queryParam = { idList: this.ids }
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出选中数据'),
        onOk() {
          that.download('order/sell/export', queryParam, `销售订单_${new Date().getTime()}.xlsx`)
          that.ids = []
          that.multiple = true
          that.selectedRowKeys = []
        },
        onCancel() {
          that.ids = []
          that.multiple = true
          that.selectedRowKeys = []
        }
      })
    },

    handleDeliveryExport() {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('销售订单.此操作将导出当所有待发货订单而非选中数据'),
        onOk() {
          that.download('order/sell/delivery/export', {}, `未发货_${new Date().getTime()}.xlsx`)
        },
        onCancel() {}
      })
    }
  }
}
</script>
<style>
.innerTable > .ant-card-body {
  padding: 0px;
}
</style>
